<template>
  <div>
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <i class="ki ki-bold-more-ver"></i>
      </template>
      <!--begin::Navigation-->
      <div class="navi navi-hover min-w-md-250px">
        <b-dropdown-text class="navi-item">
          <a @click="modal_qrcode()" class="navi-link"> QrCode </a>
        </b-dropdown-text>

        <b-dropdown-text
          tag="div"
          class="navi-separator opacity-70"
        ></b-dropdown-text>

        <b-dropdown-text class="navi-item">
          <a @click="modal_qrcode()" class="navi-link">
            Editar
          </a>
        </b-dropdown-text>
        <b-dropdown-text
          tag="div"
          class="navi-separator opacity-70"
        ></b-dropdown-text>

        <b-dropdown-text class="navi-item">
          <a @click="remover_numero()" class="navi-link">
            Remover Numero
          </a>
        </b-dropdown-text>

        
      </div>
      <!--end::Navigation-->
    </b-dropdown>

    <!-- modal para adicionar numero -->
    <div>
      <b-modal v-model="modalShow" title="QrCode ">
        <b-form-group>
          <div class="row">
            <div class="col-12 text-center">
            <img :src="qrcode.qrCode" />
            </div>
         
          </div>
        </b-form-group>

        <template #modal-footer="">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button
            size="sm"
            variant="success"
            @click="renovar_qrcode()"
          >
            Recarregar
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "menu",
  props: { telefone: {},bot:{} },
  components: {},
  created(){
     this.renovar_qrcode()
  },
  data() {
    return {
      modalShow: false,
      form:{
        numero_id:''
      }
    };
  },
  mounted() {},
  computed:{
    qrcode(){
      //  return [{id:1,numero:67984461664}]
       return  this.$store.state.chatbots.qrcode
    }
  },

  methods: {
    async renovar_qrcode() {
      console.log('passou')
    var a = {
     numero_id: this.telefone.id,
     bot_id: this.bot.id

    }  
 
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("chatbots/venon_qrcode", a).finally(() => {
        this.$store.dispatch("MudarPreloader", false);
      });
    },
    async remover_numero(){
     
      var a = {
        id:this.bot.id,
        celulares:[]
      }
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("chatbots/celulares", a).finally(() => {
        this.$store.dispatch("chatbots/all")
        this.$store.dispatch("MudarPreloader", false);
      });

    },
    async modal_qrcode() {
      this.modalShow = true;
    },

    async dados_bot(item) {
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("chatbots/get", item).finally(() => {
        this.$store.dispatch("MudarPreloader", false);
      });
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

