<template>
  <div class="row">
   <div class="col-12">
      <Bots></Bots>
   </div>
  
          
     
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
import Bots from './bots.vue';

export default {
  components:{
    // ListWidget4,
    Bots
  },
  data() {
    return {
      currentPage: 1,
      perPage: 50,
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Chatbots" }]);
  },
  computed: {
    lista() {
      return this.$store.state.chatbots.lista;
    },
  },
  created() {
    this.Listar();
  },
  methods: {
    async Listar() {
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("chatbots/all").finally(() => {
        this.$store.dispatch("MudarPreloader", false);
        this.$store.dispatch("atualizar", "");
      });
    },

    atualizar(value) {
      this.$router.push({ name: "createGradeCurricular" });
      this.$store.dispatch("atualizar", value);
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>