<template>
  <!--begin::List Widget 4-->

  <div>
    <div class="row">
      <div
        v-for="(item, index) in lista"
        :key="index"
        class="card card-custom gutter-b col-md-4 ml-5"
      >
        <!--begin::Header-->
        <div class="card-header border-3">
          <h3 class="card-title font-weight-bolder text-dark">
            {{ item.nome }}
          </h3>
          <div class="card-toolbar">
            <Menubot  :chatbot="item"></Menubot>
          </div>
        </div>
        <!--end::Header-->

        <!--begin::Body-->
        <div class="card-body pt-2 ">
          <template v-for="(nu, i) in item.celulares">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10  " v-bind:key="i">
              <!--begin::Checkbox-->
              <div class="symbol symbol-45 symbol-light mr-5">
                <!-- <span class="symbol-label">
                  <inline-svg
                    :src="item.svg"
                    class="h-50 align-self-center"
                  ></inline-svg>
                </span> -->
              </div>
              <!--end::Checkbox-->

              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1">
                <a
                  
                  class=" text-success text-hover-primary font-weight-bold font-size-lg mb-1"
                >
                  {{ nu.numero }}
                </a>
                <span class="text-muted font-weight-bold">
                  
                </span>
              </div>
              <!--end::Text-->
              <!--begin::Dropdown-->
              <Menunumero :telefone="nu" :bot="item"></Menunumero>
              <!--end::Dropdown-->
            </div>
            <!--end:Item-->
          </template>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>

  <!--end: List Widget 4-->
</template>

<script>

import Menubot from './menu_bot';
import Menunumero from './menu_numero'

export default {
  name: "bots",
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.Listar();
    this.$store.dispatch("chatbots/allTelefones");
  },
  components: {
    Menunumero,
    Menubot
  },
    
  computed: {
    lista() {
      return this.$store.state.chatbots.lista;
    },
  },
  methods: {
    async Listar() {
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("chatbots/all").finally(() => {
        this.$store.dispatch("MudarPreloader", false);
        this.$store.dispatch("atualizar", "");
      });
    },
  },
};
</script>

