<template>
  <div>
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <i class="ki ki-bold-more-ver"></i>
      </template>
      <!--begin::Navigation-->
      <div class="navi navi-hover min-w-md-250px">
        <b-dropdown-text class="navi-item">
          <a @click="editarChatbot(chatbot)" class="navi-link"> Editar </a>
        </b-dropdown-text>

        <b-dropdown-text
          tag="div"
          class="navi-separator opacity-70"
        ></b-dropdown-text>

        <b-dropdown-text class="navi-item">
          <a @click="modal_numero()" class="navi-link">
            Adicionar Numero Whats
          </a>
        </b-dropdown-text>

        <b-dropdown-text
          tag="div"
          class="navi-separator opacity-70"
        ></b-dropdown-text>

        <b-dropdown-text class="navi-item">
          <a class="navi-link"> Adiciona Site </a>
        </b-dropdown-text>
      </div>
      <!--end::Navigation-->
    </b-dropdown>

    <!-- modal para adicionar numero -->
    <div>
      <b-modal v-model="modalShow" title="Criar ">
        <b-form-group>
          <div class="row">
            <div class="col-12">
              <b-form-group
                id="input-group-1"
                label="Numero:"
                label-for="input-1"
              >
                <select v-model="form.numero_id" class="form-control">
                  <option v-for="(lista, index) in listar" :value="lista.id" :key="index">{{lista.numero}}</option>
                </select>
              </b-form-group>
            </div>
         
          </div>
        </b-form-group>

        <template #modal-footer="">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button
            size="sm"
            variant="success"
            @click="adicionar_numero()"
          >
            Adicionar
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "menu",
  props: { chatbot: {} },
  components: {},
  created(){
     
  },
  data() {
    return {
      modalShow: false,
      form:{
        numero_id:''
      }
    };
  },
  mounted() {},
  computed:{
    listar(){
      //  return [{id:1,numero:67984461664}]
      return  this.$store.state.chatbots.telefones
    }
  },

  methods: {
    async adicionar_numero() {
      var nu = this.form.numero_id
      console.log(nu)
      var a = {

        id:this.chatbot.id,
        celulares:[nu]
      }
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("chatbots/celulares", a).finally(() => {
        this.$store.dispatch("chatbots/all")
        this.$store.dispatch("MudarPreloader", false);
      });
    },
    async editarChatbot(item) {
      await this.dados_bot(item);
      this.$router.push({ name: "editarChatbot" });
    },
    async modal_numero() {
      this.modalShow = true;
    },

    async dados_bot(item) {
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("chatbots/get", item).finally(() => {
        this.$store.dispatch("MudarPreloader", false);
      });
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

